import type { State } from '../store';

export const state: State = {
  isLoading: false,
  headerSelectedRestaurant: null,
  selectedLanguage: 'pt-PT',
  subHeaderSelectedPlatform: 1,
  subHeaderSelectedProduct: null,
  subHeaderSelectedDate: null,
  allPlatformsSelected: false,
  restaurantPlatforms: [],
  restaurants: [],
  openSelectors: [],
  restaurantFilters: {
    keywords: [],
    labels: [],
    restaurants: [],
  },
  criticalErrors: {
    filters: false,
  },
  userOrderDates: [],
  pleezInsights: null,
  viewportWidth: 0,
  commonFilters: {
    platforms: [],
    addresses: [],
  },
  cityAndPlatforms: {
    cities: [],
    platforms: [],
  },
  priceComparisonCompetitorFilters: {
    sources: [],
    defaultSource: null,
    allRestaurants: [],
    defaultRestaurant: null,
    tags: [],
  },
  priceComparisonMenuFilters: {
    categories: [],
    items: [],
  },
};
