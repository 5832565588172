import { localizeTemplate } from '@/plugins/i18n';
import Routes from '@/routes/routes';
import { RouteRoot } from '@/routes/routes';
import { SideBarRoute } from './Sidebar';
import { Route } from '@/routes/routesInterface';

const mainRoots: { [key in RouteRoot]?: SideBarRoute[] } = {};

Routes.forEach((route) => {
  const root = route.meta.root;

  let children: Route['children'] = [];

  if (route.children) {
    children = route.children;
  }

  if (root) {
    if (!mainRoots[root]) {
      mainRoots[root] = [];
    }

    mainRoots[root]?.push({
      url: route.path,
      ...route.meta,
      children,
    });
  }
});

export const categoriesList = () => [
  {
    links: mainRoots[RouteRoot.Home],
  },
  {
    title: localizeTemplate('sidebar.market.label'),
    links: mainRoots[RouteRoot.Market],
    root: RouteRoot.Market,
  },
  {
    title: localizeTemplate('sidebar.competitors.label'),
    links: mainRoots[RouteRoot.Competitors],
    root: RouteRoot.Competitors,
  },
  {
    title: localizeTemplate('sidebar.management.label'),
    links: mainRoots[RouteRoot.Management],
    root: RouteRoot.Management,
  },
  {
    title: localizeTemplate('sidebar.actions.label'),
    links: mainRoots[RouteRoot.Actions],
    root: RouteRoot.Actions,
  },
  {
    title: localizeTemplate('sidebar.analytics.label'),
    links: mainRoots[RouteRoot.Analytics],
    root: RouteRoot.Analytics,
  },
];
