import { State } from '../store';

/**
 * Vuex defines getters as type any. Overriding this has been...
 * difficult. For now override the getter type directly on the file that
 * needs it :
 *
 * const vuexGetters = getters as unknown as Getters;
 */
const getters = {
  restaurantPlatforms: (state: State) => state.restaurantPlatforms,
  restaurantFilters: (state: State) => state.restaurantFilters,
  platformById: (state: State) => {
    return state.restaurantPlatforms.find(
      (platform) => platform.id === state.subHeaderSelectedPlatform,
    );
  },
  currentLanguage: (state: State) => state.selectedLanguage,
  criticalErrors: (state: State) => state.criticalErrors,
  selectedDateRange: (state: State) => {
    return {
      ...state.selectedDateRange,
      selectedDate: state.subHeaderSelectedDate,
      highlightDates: state.userOrderDates,
    };
  },
  subHeaderSelectedPlatform: (state: State) => {
    return state.subHeaderSelectedPlatform;
  },
  currentViewPort: (state: State) => {
    return state.viewportWidth;
  },
  getCommonFilters: (state: State) => {
    return state.commonFilters;
  },
  getPriceComparisonCompetitorFilters: (state: State) => {
    return state.priceComparisonCompetitorFilters;
  },
  getCityAndPlatforms: (state: State) => {
    return state.cityAndPlatforms;
  },
};

export default getters;
