import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLanguageStore = defineStore('language', () => {
  const language = ref('pt-PT');

  return {
    language,
    changeLanguage: (newLanguage: string) => {
      language.value = newLanguage;
    },
  };
});
