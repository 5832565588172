<template>
  <div @click.stop class="filters-platform__wrapper">
    <h2 class="filters-platform__title">
      {{ $t('shared.deliveryService') }}
    </h2>
    <ul class="filters-platform">
      <li v-if="availablePlatforms.length === 0" class="filters-platform__item">
        <PlatformIcon class="filters-platform__loader" isLoading />
      </li>
      <li v-for="platform in availablePlatforms" :key="platform.id" class="filters-platform__item">
        <button
          :class="[
            isCurrentlyActivePlatform(platform.id) ? 'filters-platform__button--active' : '',
            'filters-platform__button',
          ]"
          @click="$emit('click', platform.id)"
        >
          <PlatformIcon :platform="platform.name" />
        </button>
      </li>
      <li v-if="showAllPlatforms" class="filters-platform__all-platforms">
        <Checkbox
          :label="$t('filters.platforms.selectAllPlatforms')"
          @change="(state) => toggleAllPlatforms(state)"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { PlatformIcon } from '@pleez-tech/fe-components-library';
import Checkbox from '@/components/Inputs/Checkbox.vue';

import { platforms } from './filters.utils';
import { LoaderComponent } from '@pleez-tech/fe-components-library';

export default defineComponent({
  components: {
    LoaderComponent,
    PlatformIcon,
    Checkbox,
  },
  props: {
    showAllPlatforms: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availablePlatforms() {
      const restaurantPlatforms = this.$store.getters.restaurantPlatforms;
      const mappedPlatforms = restaurantPlatforms.map(
        (platform: { name: string }) => platform.name,
      );
      return platforms.filter((platform) => mappedPlatforms.includes(platform.name));
    },
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
  },
  methods: {
    isCurrentlyActivePlatform(platformIndex: number) {
      return Number(this.$store.state.subHeaderSelectedPlatform) === platformIndex;
    },
    toggleAllPlatforms(checkboxStatus: boolean) {
      this.$store.commit('setAllPlatforms', checkboxStatus);
    },
  },
  emits: ['click'],
});
</script>

<style scoped lang="scss">
.filters-platform {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  margin-top: 8px;

  &__loader {
    width: 56px;
    height: 56px;
  }
  &__title {
    font-size: 0.9rem;
    font-weight: 400;
  }
  &__item {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__button {
    padding: 1px;

    border-radius: 10px;

    &--active {
      border: 2px solid var(--brand-secondary);
    }
  }
  &__all-platforms {
    display: flex;
    align-items: center;
  }
}
</style>
