import { Mutations } from '@/store/v1/store';

const Mutations: Mutations = {
  setPlatform: function (state, id) {
    state.subHeaderSelectedPlatform = id;
  },
  setAllPlatforms: function (state, allPlatformsSelected) {
    state.allPlatformsSelected = allPlatformsSelected;
  },
  setUserOrdersDates(state, dateObject) {
    state.selectedDateRange = dateObject.dateRange;
    state.subHeaderSelectedDate = dateObject.selectedDate;
    state.userOrderDates = dateObject.orderDates || state.userOrderDates;
  },
  setPleezInsights: (state, insights) => {
    state.pleezInsights = insights;
  },
  setNewViewPort: (state, viewportWidth) => {
    state.viewportWidth = viewportWidth;
  },
  setCommonFilters: async (state, { allRestaurants: addresses, platforms }) => {
    state.commonFilters = {
      addresses,
      platforms,
    };
  },
  setCityAndPlatforms: async (state, { cities, platforms, defaultCity }) => {
    state.cityAndPlatforms = {
      cities,
      platforms,
      defaultCity,
    };
  },
  setPriceComparisonCompetitorFilters: async (
    state,
    { sources, defaultSource, allRestaurants, defaultRestaurant },
  ) => {
    state.priceComparisonCompetitorFilters = {
      sources,
      defaultSource,
      allRestaurants,
      defaultRestaurant,
    };
  },
  setSelectedLanguage: (state, language) => {
    state.selectedLanguage = language;
  },
};

export default Mutations;
