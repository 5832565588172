import { createApp } from 'vue';
import App from './App.vue';

import router, { routerInstance } from './routes/router';
import { store } from '@/store/v1/index';

import * as Sentry from '@sentry/vue';

import './globals';
import layoutsComponent from '@/layouts/';

import VueApexCharts from 'vue3-apexcharts';

import ToastPlugin from 'vue-toast-notification';

import '@/plugins/i18n/index';

import Vue3Editor from 'vue3-editor';

/**
 * google maps
 */
import VueGoogleMaps from 'vue-google-maps-community-fork';
import GoogleMapsCustomPlugin from '@/plugins/google';

/**
 * Global component registration
 */
import iconsComponents from '@/utils/_icons/iconComponentList';

import JsonCSV from 'vue-json-csv';

import VueSplide from '@splidejs/vue-splide';

import 'vue-select/dist/vue-select.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import '@splidejs/splide/dist/css/splide.min.css';
import 'flag-icons/css/flag-icons.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { setup as setupLibrary, plugin as libraryPlugin } from '@pleez-tech/fe-components-library';

const pinia = createPinia();

const app = createApp(App);

app.use(pinia);

// Keeping this one, because other component might still use
setupLibrary({ pinia });

// This is (probably) the new way to use the library
app.use(libraryPlugin.install);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://25cd4959046647e2bf9f6efe2e7e92e7@o1162067.ingest.sentry.io/6248845',
    trackComponents: true, //FIXME: Check if our rate-limit supports this
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration({
        router: routerInstance,
        tracePropagationTargets: ['core.trypleez.com'],
      }),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 0.1,
  });
}

// Define global properties
import { localizeTemplate } from '@/plugins/i18n/index';
import translations from '@/plugins/i18n/translations.json';
import { createPinia } from 'pinia';

app.config.globalProperties.$translations = translations;
app.config.globalProperties.$t = localizeTemplate;
app.config.globalProperties.$session = window.session;

app
  // Router & Store
  .use(store)
  .use(router)
  // Plugins/Components .use
  .use(ToastPlugin, {
    position: 'bottom',
    duration: 5000,
    queue: true,
  })
  .use(VueApexCharts)
  .use(VueSplide)
  .use(Vue3Editor)
  // Components .component
  .component('downloadCsv', JsonCSV);

// Layouts
app.use(layoutsComponent);

// Icons Components
app.use(iconsComponents);

// Google Maps Components
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
});
app.use(GoogleMapsCustomPlugin, {
  paths: ['/market/ranking'],
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY!,
});

const globals = app.config.globalProperties;
export { globals };

export default app.mount('#app');

if (window.Cypress) {
  window.app = app;
  window.store = store;
}
