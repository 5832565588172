import HttpRequest from '@/http/httpClass';
import { defineStore } from 'pinia';
import type { Platform } from './Platform';
import { ref } from 'vue';

export const usePlatformsStore = defineStore('platforms', () => {
  const platforms = ref<Platform[]>([]);
  const userPlatforms = ref<Platform[]>([]);

  const fetchPlatforms = async () => {
    if (!platforms.value.length) {
      const request = new HttpRequest('/platforms', 'GET', true);

      platforms.value = await request.send();
    }
  };

  const fetchUserPlatforms = async () => {
    if (!userPlatforms.value.length) {
      const response = await HttpRequest.get<Platform[]>('/v2/platform?forUser=true');
      userPlatforms.value = response.data;
    }
  };

  const getPlatformFromId = (platformId: number) =>
    platforms.value.find((p) => p.id === platformId);

  return { platforms, userPlatforms, fetchPlatforms, fetchUserPlatforms, getPlatformFromId };
});
