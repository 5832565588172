import { getCurrentInstance } from 'vue';
import { SideBarRoute } from '@/components/Sidebar/Sidebar';
import { RouteMeta } from 'vue-router';

const VueProperties = getCurrentInstance()?.appContext.config.globalProperties || {
  $session: {
    getSession: (sessionName: string) => sessionStorage.getItem(sessionName),
  },
};

export const isLockedByRole = (link: SideBarRoute | RouteMeta): boolean | boolean[] | undefined => {
  const lockedRoles = link.hiddenLinkRoles as string[];
  if (!lockedRoles) return false;
  const routeBlockedByRole = lockedRoles.some((role) => userRoles().includes(role));
  return routeBlockedByRole;
};

export const isLockedForZonesoft = (link: SideBarRoute | RouteMeta): boolean => {
  const routeBlockedForZonesoft = Boolean(link.isZonesoftHidden && isZonesoftUser());
  return routeBlockedForZonesoft;
};

export const isZonesoftUser = (): boolean => {
  return Boolean(VueProperties.$session.getSession('api-key'));
};

export const userHasAnyMatchingRole = (roles: string[]) =>
  userRoles().some((r) => roles.includes(r));

export const userRoles = (): string[] => {
  let roles: string | string[] = VueProperties.$session.getSession('pleez-roles') || [];

  if (!Array.isArray(roles)) {
    roles = roles.split(',');
  }

  return roles;
};
