import { defineAsyncComponent } from 'vue';
import { App } from 'vue';

export default {
  install(app: App) {
    app.component(
      'ApproveIcon',
      defineAsyncComponent(() => import('@/assets/icons/approve-icon.svg?component')),
    );
    app.component(
      'ArrowIcon',
      defineAsyncComponent(() => import('@/assets/icons/forward.svg?component')),
    );
    app.component(
      'CalendarIcon',
      defineAsyncComponent(() => import('@/assets/icons/calendar.svg?component')),
    );
    app.component(
      'ChevronIcon',
      defineAsyncComponent(() => import('@/assets/icons/chevron.svg?component')),
    );
    app.component(
      'CirclePlusIcon',
      defineAsyncComponent(() => import('@/assets/icons/circle-plus.svg?component')),
    );
    app.component(
      'CoinIcon',
      defineAsyncComponent(() => import('@/assets/icons/coin.svg?component')),
    );
    app.component(
      'CrosshairIcon',
      defineAsyncComponent(() => import('@/assets/icons/crosshair.svg?component')),
    );
    app.component(
      'DisapproveIcon',
      defineAsyncComponent(() => import('@/assets/icons/disapprove-icon.svg?component')),
    );
    app.component(
      'DownloadIcon',
      defineAsyncComponent(() => import('@/assets/icons/download.svg?component')),
    );
    app.component(
      'EyeIcon',
      defineAsyncComponent(() => import('@/assets/icons/eye.svg?component')),
    );
    app.component(
      'InfoIcon',
      defineAsyncComponent(() => import('@/assets/icons/info.svg?component')),
    );
    app.component(
      'LogoutIcon',
      defineAsyncComponent(() => import('@/assets/icons/logout.svg?component')),
    );
    app.component(
      'MagnifyingGlassIcon',
      defineAsyncComponent(() => import('@/assets/icons/magnifying-glass.svg?component')),
    );
    app.component(
      'MaximizeIcon',
      defineAsyncComponent(() => import('@/assets/icons/maximize.svg?component')),
    );
    app.component(
      'MenuIcon',
      defineAsyncComponent(() => import('@/assets/icons/menu.svg?component')),
    );
    app.component(
      'PleezIcon',
      defineAsyncComponent(() => import('@/assets/icons/pleez.svg?component')),
    );
    app.component(
      'PleezLogo',
      defineAsyncComponent(() => import('@/assets/icons/pleez-logo.svg?component')),
    );
    app.component(
      'ProfileIcon',
      defineAsyncComponent(() => import('@/assets/icons/profile.svg?component')),
    );
    app.component(
      'PulseIcon',
      defineAsyncComponent(() => import('@/assets/icons/pulse.svg?component')),
    );
    app.component(
      'ReportIcon',
      defineAsyncComponent(() => import('@/assets/icons/report.svg?component')),
    );
    app.component(
      'SettingsIcon',
      defineAsyncComponent(() => import('@/assets/icons/settings.svg?component')),
    );
    app.component(
      'SquaresIcon',
      defineAsyncComponent(() => import('@/assets/icons/squares.svg?component')),
    );
    app.component(
      'TargetIcon',
      defineAsyncComponent(() => import('@/assets/icons/target.svg?component')),
    );
    app.component(
      'CheckmarkIcon',
      defineAsyncComponent(() => import('@/assets/icons/checkmark.svg?component')),
    );
    app.component(
      'ChartUpIcon',
      defineAsyncComponent(() => import('@/assets/icons/chart-up.svg?component')),
    );
    app.component(
      'ChartDownIcon',
      defineAsyncComponent(() => import('@/assets/icons/chart-down.svg?component')),
    );
    app.component(
      'CloseIcon',
      defineAsyncComponent(() => import('@/assets/icons/close.svg?component')),
    );
    app.component(
      'HomeIcon',
      defineAsyncComponent(() => import('@/assets/icons/home.svg?component')),
    );
    app.component(
      'PercentageIcon',
      defineAsyncComponent(() => import('@/assets/icons/percentage.svg?component')),
    );
    app.component(
      'EditIcon',
      defineAsyncComponent(() => import('@/assets/icons/edit.svg?component')),
    );
    app.component(
      'SortDefaultIcon',
      defineAsyncComponent(() => import('@/assets/icons/sort-default.svg?component')),
    );

    app.component(
      'UberEatsIcon',
      defineAsyncComponent(() => import('@/assets/icons/uber-eats-platform.svg?component')),
    );
    app.component(
      'BoltIcon',
      defineAsyncComponent(() => import('@/assets/icons/bolt-platform.svg?component')),
    );
    app.component(
      'GlovoIcon',
      defineAsyncComponent(() => import('@/assets/icons/glovo-platform.svg?component')),
    );
    app.component(
      'JustEatIcon',
      defineAsyncComponent(() => import('@/assets/icons/just-eat-platform.svg?component')),
    );
    app.component(
      'DeliverooIcon',
      defineAsyncComponent(() => import('@/assets/icons/deliveroo-platform.svg?component')),
    );
    app.component(
      'YouIcon',
      defineAsyncComponent(() => import('@/assets/icons/you-platform.svg?component')),
    );

    app.component(
      'CogIcon',
      defineAsyncComponent(() => import('@/assets/icons/cog.svg?component')),
    );
    app.component(
      'LinkedinIcon',
      defineAsyncComponent(() => import('@/assets/icons/linkedin.svg?component')),
    );
    app.component(
      'InstagramIcon',
      defineAsyncComponent(() => import('@/assets/icons/instagram.svg?component')),
    );
    app.component(
      'FacebookIcon',
      defineAsyncComponent(() => import('@/assets/icons/facebook.svg?component')),
    );
    app.component(
      'WhatsappIcon',
      defineAsyncComponent(() => import('@/assets/icons/whatsapp.svg?component')),
    );
    app.component(
      'EmailIcon',
      defineAsyncComponent(() => import('@/assets/icons/email.svg?component')),
    );
    app.component(
      'StarIcon',
      defineAsyncComponent(() => import('@/assets/icons/star-icon.svg?component')),
    );
    app.component(
      'TurnOffIcon',
      defineAsyncComponent(() => import('@/assets/icons/turn-off.svg?component')),
    );
    app.component(
      'WindIcon',
      defineAsyncComponent(() => import('@/assets/icons/wind.svg?component')),
    );
    app.component(
      'MenuScrollIcon',
      defineAsyncComponent(() => import('@/assets/icons/menu-scroll.svg?component')),
    );
  },
};
