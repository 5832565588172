/**
 * Here contains the routes of the application.
 * And is imported in the main.ts file.
 */

import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { isLockedByRole, userHasAnyMatchingRole } from '@/utils/roles';
import { App, nextTick } from 'vue';

const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' };
  },
});

export const routerInstance = router;

export default {
  install(app: App) {
    const $session = app.config.globalProperties.$session;
    router.install(app);

    const superUserLockedRoutes = routes
      .filter((route) => {
        const isSuperUserLocked = route.meta.isSuperUserLocked;

        if (Array.isArray(isSuperUserLocked)) {
          return isSuperUserLocked[route.meta.breadcrumbs?.activeSubPageIndex || 0];
        } else {
          return isSuperUserLocked;
        }
      })
      .map((route) => route.path);

    router.beforeEach((to, from, next) => {
      const routeBlockedByRole = isLockedByRole(to.meta);
      const pleezToken = $session.getSession('pleez-token');
      const zonesoftToken = $session.getSession('api-key');
      const notSuperUser = $session.getSession('isSuperUser') === 'false';
      const routeSuperUserLocked =
        notSuperUser && superUserLockedRoutes.findIndex((route) => route === to.path) !== -1;
      const routeZonesoftLocked = to.meta.isZonesoftLocked;

      // For redirect when user is not logged in
      if (from.path !== '/' && from.path !== '/forgot-password') {
        to.meta.path = from.path;
      }

      if (
        (zonesoftToken && routeZonesoftLocked) ||
        (pleezToken && (routeSuperUserLocked || routeBlockedByRole)) ||
        (to.meta.allowedRoles && !userHasAnyMatchingRole(to.meta.allowedRoles as string[]))
      ) {
        next('/home');
      } else {
        next();
      }
    });

    router.afterEach((_, from) => {
      /**
       * If page is not being refreshed
       * scroll authenticated layout content
       * to the top
       */
      if (from.path !== '/') {
        nextTick(() => {
          const body = document.querySelector('.content__body');
          body?.scrollTo(0, 0);
        });
      }
    });
  },
};
